/* All over the Application */
.custom-button {
  background-color: #176fbf;
  width: 185px;
  border-radius: 100px;
  border: none;
}

.custom-button:hover {
  background-color: #176fbf;
}

.text-blue {
  color: #176fbf;
}

/* .hero-section {
  background-image: url(./assets/hero_background.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
} */

.blue-cover-circle {
  /* Ellipse 4 */
  position: absolute;
  width: 1700px;
  height: 1700px;
  border-radius: 2300px;
  left: 0;
  top: 0;
  transform: translate(-30%, -40%);
  background: #00bcd3;
  opacity: 0.5;
  filter: blur(156px);
  z-index: -2;
}

.idea {
  background-image: url("./assets/idea_background.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 742px;
}

.custom-image {
  max-width: 450px;
}

/* Contact Section */
.contact-section {
  background-color: #9a62d6;
  position: relative;
  min-height: 700px;
  margin-top: 15rem;
}

.contact-section::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 15%;
  width: 50%;
  height: 100%;
  background-image: url(./assets//contact_background_1.png);
  background-size: contain;
  background-position: left;
  background-repeat: no-repeat;
  z-index: 4;
}

.contact-section::after {
  content: "";
  position: absolute;
  right: 0;
  bottom: 8%;
  width: 50%;
  height: 100%;
  background-image: url(./assets/contact_background_2.png);
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  z-index: 4;
}

.contact-form {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  bottom: 30%;
  border-radius: 28px;
  background-color: #fff;
  box-shadow: 0px 8px 80px 0px rgba(135, 104, 165, 0.25);
  color: black;
  max-width: 750px;
  margin: 0 auto;
  padding: 50px 80px;
  z-index: 10;
}

.contact-form .form-control {
  border: 0;
  border-radius: 0;
  border-bottom: 1px solid #176fbf;
  outline: none;
}

.contact-form .form-control:focus {
  outline: none;
  box-shadow: none;
}

.contact-form .custom-button {
  width: 260px;
}

/* Footer Section */
.cta-section {
  background-color: #9a62d6;
  position: relative;
  padding-block: 25px 150px;
}

.cta-section div {
  position: relative;
  z-index: 10;
}

.cta-section::after {
  /* Ellipse 5 */
  content: "";
  position: absolute;
  width: 75%;
  height: 100%;
  border-radius: 75%;
  left: 50%;
  bottom: 0;
  transform: translate(-50%);
  z-index: 2;
  background: rgba(207, 153, 232, 0.9);
  filter: blur(156px);
}

footer {
  background-color: #fff;
  padding: 35px 0;
  position: relative;
  z-index: 10;
}

.social-icon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #7362c9;
}

.social-icon .facebook-icon {
  width: 12px;
}

.social-icon .twitter-icon,
.social-icon .instagram-icon {
  width: 18px;
}

/* Media Queries */
@media (max-width: 992px) {
  .contact-section::before,
  .contact-section::after {
    width: 30%;
    bottom: 30%;
  }

  .contact-form {
    padding: 50px 50px;
  }
}

@media (max-width: 768px) {
  .contact-section {
    margin-top: 17rem;
  }

  .contact-section::before,
  .contact-section::after {
    width: 30%;
    bottom: 30%;
  }

  .contact-form {
    padding: 50px 30px;
  }
}

@media screen and (max-width: 576px) {
  /* All over the Application */
  .custom-button {
    width: 100px;
  }

  .custom-image {
    width: 100%;
  }

  /* Contact Page */
  .fune-address {
    width: 100%;
  }

  .fune-address img {
    width: 100%;
  }

  /* Contact Section */
  .contact-section {
    margin-top: 18rem;
  }

  .contact-section::before,
  .contact-section::after {
    display: none;
  }

  .contact-form {
    padding: 50px 30px;
  }

  /* Footer */
  .cta-section .container {
    padding-bottom: 150px;
  }

  .cta-section::after {
    height: 75%;
  }

  .footer {
    padding: 20px;
  }

  .footer .containter {
    padding: 0;
  }

  .social-icon {
    width: 30px;
    height: 30px;
  }

  .social-icon .facebook-icon {
    width: 8px;
  }

  .social-icon .twitter-icon,
  .social-icon .instagram-icon {
    width: 12px;
  }
}
