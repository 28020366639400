.functionality-section {
  background-image: url(../../assets/girl_with_phone.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 900px;
}

.bd-radius {
  border-radius: 30px;
}

.vooraf-section {
  background-image: url(../../assets/voorf.png);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 900px;
}
