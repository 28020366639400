.navbar-nav .nav-item {
  padding-left: 1rem;
  padding-right: 1rem;
}

.navbar-nav .nav-link {
  font-weight: 600;
}

.light-link {
  color: #fff;
}

.dark-link {
  color: #000;
}

.navbar-nav .nav-link.active {
  color: #986dc2;
}

.loginLanguage {
  color: #fff;
  font-weight: 600;
}

.loginLanguageSecondary {
  color: #000;
  font-weight: 600;
}

.download-button {
  color: #fff;
  background-color: #176fbf;
  width: 155px;
  border-radius: 100px;
  border: none;
  padding: 5px;
}
.vooraf-button {
  color: #fff;
  background-color: #176fbf;
  border-radius: 100px;
  border: none;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
}

.nav-background {
  background-color: #fff;
}

.language-visibility {
  display: none !important;
}

.nav-margin {
  margin: 0 !important;
}
