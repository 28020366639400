.try-fun-section-wrapper {
  background-color: #fff;
  padding: 100px 0;
}

.try-fun-section-wrapper .text-side h3 {
  color: #70cb94;
  font-size: 16px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.try-fun-section-wrapper .text-side h2 {
  color: #000;
  font-size: 52px;
  font-weight: 700;
  line-height: 64px;
  letter-spacing: -1px;
}

.try-fun-section-wrapper .text-side p {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 32px;
}

.try-fun-section-wrapper .text-side img {
  border-radius: 30px;
}

.try-fun-section-wrapper .form-side {
  position: relative;
}

.try-fun-section-wrapper .form-side img {
  position: absolute;
  bottom: -35px;
  width: 275px;
  left: 30px;
  border-radius: 16px;
}

.try-fun-section-wrapper .form-side .form-wrapper {
  border-radius: 28px;
  background: #7466bb;
  padding: 40px 32px 32px 32px;
  max-width: 450px;
  position: relative;
  z-index: 9;
}

.try-fun-section-wrapper .form-side .form-wrapper h2 {
  color: #fff;
  font-size: 29px;
  font-weight: 700;
  line-height: 120%;
}

.try-fun-section-wrapper .form-side .form-wrapper .form-label {
  margin-bottom: 0;
  color: #fff;
  font-weight: 700;
}

.try-fun-section-wrapper .form-side .form-wrapper .form-control,
.try-fun-section-wrapper .form-side .form-wrapper .form-control::placeholder {
  background-color: transparent !important;
  border: 0;
  border-bottom: 1px solid #fff;
  box-shadow: initial;
  outline: 0;
  border-radius: 0;
  padding-left: 0;
  font-weight: 700;
  font-size: 18px;
  color: #fff;
}

.try-fun-section-wrapper .form-side .form-wrapper .form-control::placeholder {
  border: 0;
}

.try-fun-section-wrapper .form-side .form-wrapper .submit-btn {
  color: #176fbf;
  padding: 18px 16px;
  border-radius: 100px;
  background-color: #f5f5f5;
  width: 160px;
  font-weight: 700;
}
